import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

const ChartVisual = ({ data }) => {
  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        data: Object.values(data),
        backgroundColor: ["#df4545", "#ffb54a", "#4aaaff"],
        hoverBackgroundColor: ["#fff", "#fff", "#fff"],
      },
    ],
  };

  const options = {
    elements: {
      arc: {
        borderWidth: 4,
        borderColor: "#000",
      },
    },
  };

  return (
    <div>
      <Doughnut className="w-[400px]" data={chartData} options={options} />
    </div>
  );
};

export default ChartVisual;
