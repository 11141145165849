export const dataCombinator = (data) => {
  const combinedData = [];

  data.forEach((item) => {
    const domain = getDomainFromUrl(item.url);

    const existing = combinedData.find(
      (d) => getDomainFromUrl(d.url) === domain
    );

    if (existing) {
      existing.time_spent = existing.time_spent + item.time_spent;
    } else {
      combinedData.push({ ...item, url: item.url });
    }
  });

  return combinedData;
};

export const getDomainFromUrl = (url) => {
  try {
    const { hostname } = new URL(url);
    return hostname;
  } catch (error) {
    console.error("Invalid URL:", url);
    return null;
  }
};

export const getTimespent = (time) => {
  // convert time ms to hours, minutes, seconds
  const hours = Math.floor(time / 3600000);
  const minutes = Math.floor((time % 3600000) / 60000);
  const seconds = Math.floor(((time % 3600000) % 60000) / 1000);

  // convert to HH:MM:SS format
  const HH = hours < 10 ? `0${hours}` : hours;
  const MM = minutes < 10 ? `0${minutes}` : minutes;
  const SS = seconds < 10 ? `0${seconds}` : seconds;

  return `${HH}:${MM}:${SS}`;
};

export const todayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1; // January is 0
  const day = today.getDate();

  return `${year}-${month}-${day}`;
};

export const workingTimeLeft = () => {
  // return counting down time left in workday from 9 to 17
  const today = new Date();
  const hours = today.getHours();
  const minutes = today.getMinutes();

  const workdayStart = new Date();
  workdayStart.setHours(9, 0, 0, 0);

  const workdayEnd = new Date();
  workdayEnd.setHours(17, 30, 0, 0);

  const workdayLeftMillis = workdayEnd - today;

  const workdayLeftHours = Math.floor(workdayLeftMillis / 3600000);
  const workdayLeftMinutes = Math.floor((workdayLeftMillis % 3600000) / 60000);
  const workdayLeftSeconds = Math.floor(
    ((workdayLeftMillis % 3600000) % 60000) / 1000
  );

  const HH = workdayLeftHours < 10 ? `0${workdayLeftHours}` : workdayLeftHours;
  const MM =
    workdayLeftMinutes < 10 ? `0${workdayLeftMinutes}` : workdayLeftMinutes;
  const SS =
    workdayLeftSeconds < 10 ? `0${workdayLeftSeconds}` : workdayLeftSeconds;

  // stop timer if workday is over which is 17:30
  if (hours >= 17 && minutes >= 30) {
    return "00:00:00";
  }

  return `${HH}:${MM}:${SS}`;
};
