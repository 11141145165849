import React, { useEffect, useState } from "react";
import Pusher from "pusher-js";
import axios from "axios";
import ChartVisual from "./components/Chart";
import CountDown from "./components/CountDown";
import categories from "./categories.json";
import {
  getDomainFromUrl,
  getTimespent,
  dataCombinator,
  todayDate,
} from "./utils";

const App = () => {
  const [tabData, setTabData] = useState([]);
  const [historyDates, setHistoryDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  // reorder historyDates by date
  historyDates.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  // reorder tabData by time_spent
  tabData.sort((a, b) => {
    return b.time_spent - a.time_spent;
  });

  useEffect(() => {
    setSelectedDate(todayDate());
    axios
      .get("https://tab-tracker-api.g-home.site/api/history-dates")
      .then((response) => {
        setHistoryDates(response.data);
      });

    axios
      .get("https://tab-tracker-api.g-home.site/api/tab-data-today")
      .then((response) => {
        const combinedData = dataCombinator(response.data);
        setTabData(combinedData);
      })
      .catch((error) => console.error("Error fetching data:", error));

    const pusher_key = process.env.REACT_APP_PUSHER_KEY;
    const pusher = new Pusher(`${pusher_key}`, {
      cluster: "eu",
    });

    const channel = pusher.subscribe("tab-data");
    channel.bind("App\\Events\\TabDataUpdated", (newData) => {
      setTabData((prevData) => {
        return dataCombinator([...prevData, newData.tabData]);
      });
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  const categorizedData = {
    social: 0,
    work: 0,
    others: 0,
  };

  tabData.forEach((tab) => {
    const domain = new URL(tab.url).hostname;
    let foundCategory = false;

    for (const [category, domains] of Object.entries(categories)) {
      if (domains.includes(domain)) {
        categorizedData[category] += tab.time_spent;
        foundCategory = true;
        break;
      }
    }

    if (!foundCategory) {
      categorizedData.others += tab.time_spent;
    }
  });

  const totalWorkdayMillis = 8 * 60 * 60 * 1000; // 8 hours in milliseconds

  const calculatePercentages = () => {
    let percentages = {};
    for (const [category, time] of Object.entries(categorizedData)) {
      percentages[category] = (time / totalWorkdayMillis) * 100;
    }
    return percentages;
  };

  const categoryPercentages = calculatePercentages();

  const dateToText = (date) => {
    // transform date from 2021-10-10 to 10 Oct 2023
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString("default", { month: "short" });
    const year = dateObj.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const handleChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);

    fetchDataBasedOnDate(newDate);
  };

  const fetchDataBasedOnDate = (date) => {
    axios
      .get(`https://tab-tracker-api.g-home.site/api/tab-data/${date}`)
      .then((response) => {
        const combinedData = dataCombinator(response.data);
        setTabData(combinedData);
      })
      .catch((error) => {
        console.error("Error fetching data based on date: ", error);
      });
  };

  return (
    <div className="h-screen bg-black flex items-center">
      <div className="mx-auto container grid grid-cols-12">
        <div className="col-span-7">
          <div className="flex justify-between mb-6">
            <div>
              <p className="text-white text-4xl font-bold mb-1">
                {selectedDate === todayDate() ? todayDate() : selectedDate}
              </p>
              {selectedDate === todayDate() && <CountDown />}
            </div>
            <div className="text-white">
              <select
                className="w-56 h-12 bg-white/10 rounded-xl p-3"
                value={selectedDate}
                onChange={handleChange}
              >
                {historyDates.map((dateItem, index) => (
                  <option value={dateItem.date} key={index}>
                    {dateToText(dateItem.date)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="max-h-[50vh]">
            {tabData.map((data, id) => (
              <div
                key={id}
                className="flex text-white items-center w-full bg-[#272a32] py-3 px-4 my-1 rounded-xl"
              >
                {!data.favicon ? (
                  <span className="w-7 h-7 bg-white rounded-xl inline-flex justify-center items-center mr-3 text-black shrink-0">
                    ?
                  </span>
                ) : (
                  <img
                    src={data.favicon}
                    alt="favicon"
                    className="w-7 h-7 mr-3"
                  />
                )}

                <p className="mr-10">{getDomainFromUrl(data.url)}</p>
                <span className="ml-auto text-sm">
                  {getTimespent(data.time_spent)}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="fixed bottom-10 right-10">
          <ChartVisual data={categoryPercentages} />
        </div>
      </div>
    </div>
  );
};

export default App;
