import React, { useState, useEffect } from "react";

const WorkingTimeLeft = () => {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const calculateTimeLeft = () => {
    // Your existing logic to calculate time left
    const today = new Date();
    const workdayEnd = new Date();
    workdayEnd.setHours(17, 30, 0, 0);

    const workdayLeftMillis = workdayEnd - today;
    const workdayLeftHours = Math.floor(workdayLeftMillis / 3600000);
    const workdayLeftMinutes = Math.floor(
      (workdayLeftMillis % 3600000) / 60000
    );
    const workdayLeftSeconds = Math.floor(
      ((workdayLeftMillis % 3600000) % 60000) / 1000
    );

    const HH =
      workdayLeftHours < 10 ? `0${workdayLeftHours}` : workdayLeftHours;
    const MM =
      workdayLeftMinutes < 10 ? `0${workdayLeftMinutes}` : workdayLeftMinutes;
    const SS =
      workdayLeftSeconds < 10 ? `0${workdayLeftSeconds}` : workdayLeftSeconds;

    // stop timer if workday is over which is 17:30
    if (today.getHours() >= 17 && today.getMinutes() >= 30) {
      return "00:00:00";
    }

    return `${HH}:${MM}:${SS}`;
  };

  return <p className="text-white">Time left in workday: {timeLeft}</p>;
};

export default WorkingTimeLeft;
